@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'Raleway-Black';
  src: url('../public/fonts/Raleway-Black.ttf') format('ttf');
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('../public/fonts/Raleway-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'Raleway-Regular';
  src: url('../public/fonts/Raleway-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Raleway-Medium';
  src: url('../public/fonts/Raleway-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Raleway-Thin';
  src: url('../public/fonts/Raleway-Thin.ttf') format('ttf');
}

body {
  overflow-x: hidden;
  user-select: none;
}